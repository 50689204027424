export default function TextGenWebUIOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="flex gap-4 flex-wrap">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Base URL
        </label>
        <input
          type="url"
          name={`TextGenWebUIBasePath${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder="http://127.0.0.1:5000/v1"
          defaultValue={settings?.[`TextGenWebUIBasePath${moduleSuffix}`]}
          required={true}
          autoComplete="off"
          spellCheck={false}
        />
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Token context window
        </label>
        <input
          type="number"
          name={`TextGenWebUITokenLimit${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder="Content window limit (eg: 4096)"
          min={1}
          onScroll={(e) => e.target.blur()}
          defaultValue={settings?.[`TextGenWebUITokenLimit${moduleSuffix}`]}
          required={true}
          autoComplete="off"
        />
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          API Key (Optional)
        </label>
        <input
          type="password"
          name={`TextGenWebUIAPIKey${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder="TextGen Web UI API Key"
          defaultValue={
            settings?.[`TextGenWebUIAPIKey${moduleSuffix}`]
              ? "*".repeat(20)
              : ""
          }
          autoComplete="off"
          spellCheck={false}
        />
      </div>
    </div>
  );
}
