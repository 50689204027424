import React, { useState } from "react";
import System from "@/models/system";
import showToast from "@/utils/toast";
import { useTranslation } from "react-i18next";

export default function YoutubeOptions() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    try {
      setLoading(true);
      showToast(t("show-toast.fetching-transcript"), "info", {
        clear: true,
        autoClose: false,
      });

      const { data, error } = await System.dataConnectors.youtube.transcribe({
        url: form.get("url"),
      });

      if (!!error) {
        showToast(error, "error", { clear: true });
        setLoading(false);
        return;
      }

      showToast(
        `${data.title} by ${data.author} transcription completed. Output folder is ${data.destination}.`,
        "success",
        { clear: true }
      );
      e.target.reset();
      setLoading(false);
      return;
    } catch (e) {
      console.error(e);
      showToast(e.message, "error", { clear: true });
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex flex-col w-full px-1 md:pb-6 pb-16">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="w-full flex flex-col py-2">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col pr-10">
                <div className="flex flex-col gap-y-1 mb-4">
                  <label className="normal-text font-bold text-sm flex gap-x-2 items-center">
                    {t("dataConnectors.youtube-transcript.url")}
                  </label>
                  <p className="text-xs font-normal normal-text">
                    {t("dataConnectors.youtube-transcript.url-video")}
                  </p>
                </div>
                <input
                  type="url"
                  name="url"
                  className="dark-input-mdl w-full normal-text  text-sm rounded-md block p-2"
                  placeholder="https://youtube.com/watch?v=abc123"
                  required={true}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 w-full pr-10">
            <button
              type="submit"
              disabled={loading}
              className="primary-bg mt-2 w-full justify-center text-white px-4 py-2 rounded-md text-sm font-bold items-center flex gap-x-2 disabled:cursor-not-allowed"
            >
              {loading
                ? t("dataConnectors.youtube-transcript.collecting")
                : t("dataConnectors.youtube-transcript.collect")}
            </button>
            {loading && (
              <p className="text-xs font-normal normal-text">
                {t("dataConnectors.youtube-transcript.desc-end")}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
