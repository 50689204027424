export default function HuggingFaceOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            HuggingFace Inference Endpoint
          </label>
          <input
            type="url"
            name={`HuggingFaceLLMEndpoint${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
            placeholder="https://example.endpoints.huggingface.cloud"
            defaultValue={settings?.[`HuggingFaceLLMEndpoint${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            HuggingFace Access Token
          </label>
          <input
            type="password"
            name={`HuggingFaceLLMAccessToken${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
            placeholder="HuggingFace Access Token"
            defaultValue={
              settings?.[`HuggingFaceLLMAccessToken${moduleSuffix}`]
                ? "*".repeat(20)
                : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Model Token Limit
          </label>
          <input
            type="number"
            name={`HuggingFaceLLMTokenLimit${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
            placeholder="4096"
            min={1}
            onScroll={(e) => e.target.blur()}
            defaultValue={settings?.[`HuggingFaceLLMTokenLimit${moduleSuffix}`]}
            required={true}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
}
