import PreLoader from "@/components/Preloader";
import System from "@/models/system";
import AnythingLLMIcon from "@/media/logo/anything-llm-icon.png";
import OpenAiLogo from "@/media/llmprovider/openai.png";
import GenericOpenAiLogo from "@/media/llmprovider/generic-openai.png";
import AzureOpenAiLogo from "@/media/llmprovider/azure.png";
import AnthropicLogo from "@/media/llmprovider/anthropic.png";
import GeminiLogo from "@/media/llmprovider/gemini.png";
import OllamaLogo from "@/media/llmprovider/ollama.png";
import TogetherAILogo from "@/media/llmprovider/togetherai.png";
import FireworksAILogo from "@/media/llmprovider/fireworksai.jpeg";
import LMStudioLogo from "@/media/llmprovider/lmstudio.png";
import LocalAiLogo from "@/media/llmprovider/localai.png";
import MistralLogo from "@/media/llmprovider/mistral.jpeg";
import HuggingFaceLogo from "@/media/llmprovider/huggingface.png";
import PerplexityLogo from "@/media/llmprovider/perplexity.png";
import OpenRouterLogo from "@/media/llmprovider/openrouter.jpeg";
import GroqLogo from "@/media/llmprovider/groq.png";
import KoboldCPPLogo from "@/media/llmprovider/koboldcpp.png";
import TextGenWebUILogo from "@/media/llmprovider/text-generation-webui.png";
import LiteLLMLogo from "@/media/llmprovider/litellm.png";
import AWSBedrockLogo from "@/media/llmprovider/bedrock.png";
import DeepSeekLogo from "@/media/llmprovider/deepseek.png";

import CohereLogo from "@/media/llmprovider/cohere.png";
import ZillizLogo from "@/media/vectordbs/zilliz.png";
import AstraDBLogo from "@/media/vectordbs/astraDB.png";
import ChromaLogo from "@/media/vectordbs/chroma.png";
import PineconeLogo from "@/media/vectordbs/pinecone.png";
import LanceDbLogo from "@/media/vectordbs/lancedb.png";
import WeaviateLogo from "@/media/vectordbs/weaviate.png";
import QDrantLogo from "@/media/vectordbs/qdrant.png";
import MilvusLogo from "@/media/vectordbs/milvus.png";
import VoyageAiLogo from "@/media/embeddingprovider/voyageai.png";
import JinaLogo from "@/media/embeddingprovider/jina.png";

import React, { useState, useEffect } from "react";
import paths from "@/utils/paths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const getLLMSelectionPrivacy = (t) => ({
  openai: {
    name: "OpenAI",
    description: [
      t("llm-selection-privacy.openai.description.0"),
      t("llm-selection-privacy.openai.description.1"),
    ],
    logo: OpenAiLogo,
  },
  azure: {
    name: "Azure OpenAI",
    description: [
      t("llm-selection-privacy.azure.description.0"),
      t("llm-selection-privacy.azure.description.1"),
    ],
    logo: AzureOpenAiLogo,
  },
  anthropic: {
    name: "Anthropic",
    description: [
      t("llm-selection-privacy.anthropic.description.0"),
      t("llm-selection-privacy.anthropic.description.1"),
    ],
    logo: AnthropicLogo,
  },
  gemini: {
    name: "Google Gemini",
    description: [
      t("llm-selection-privacy.gemini.description.0"),
      t("llm-selection-privacy.gemini.description.1"),
    ],
    logo: GeminiLogo,
  },
  lmstudio: {
    name: "LMStudio",
    description: [t("llm-selection-privacy.lmstudio.description.0")],
    logo: LMStudioLogo,
  },
  localai: {
    name: "LocalAI",
    description: [t("llm-selection-privacy.localai.description.0")],
    logo: LocalAiLogo,
  },
  ollama: {
    name: "Ollama",
    description: [t("llm-selection-privacy.ollama.description.0")],
    logo: OllamaLogo,
  },
  native: {
    name: "Custom Llama Model",
    description: [t("llm-selection-privacy.native.description.0")],
    logo: AnythingLLMIcon,
  },
  togetherai: {
    name: "TogetherAI",
    description: [
      t("llm-selection-privacy.togetherai.description.0"),
      t("llm-selection-privacy.togetherai.description.1"),
    ],
    logo: TogetherAILogo,
  },
  fireworksai: {
    name: "FireworksAI",
    description: [
      "Your chats will not be used for training",
      "Your prompts and document text used in response creation are visible to Fireworks AI",
    ],
    logo: FireworksAILogo,
  },
  mistral: {
    name: "Mistral",
    description: [t("llm-selection-privacy.mistral.description.0")],
    logo: MistralLogo,
  },
  huggingface: {
    name: "HuggingFace",
    description: [t("llm-selection-privacy.huggingface.description.0")],
    logo: HuggingFaceLogo,
  },
  perplexity: {
    name: "Perplexity AI",
    description: [
      t("llm-selection-privacy.perplexity.description.0"),
      t("llm-selection-privacy.perplexity.description.1"),
    ],
    logo: PerplexityLogo,
  },
  openrouter: {
    name: "OpenRouter",
    description: [
      t("llm-selection-privacy.openrouter.description.0"),
      t("llm-selection-privacy.openrouter.description.1"),
    ],
    logo: OpenRouterLogo,
  },
  groq: {
    name: "Groq",
    description: [
      t("llm-selection-privacy.groq.description.0"),
      t("llm-selection-privacy.groq.description.1"),
    ],
    logo: GroqLogo,
  },
  koboldcpp: {
    name: "KoboldCPP",
    description: [t("llm-selection-privacy.koboldcpp.description.0")],
    logo: KoboldCPPLogo,
  },
  textgenwebui: {
    name: "Oobabooga Web UI",
    description: [t("llm-selection-privacy.textgenwebui.description.0")],
    logo: TextGenWebUILogo,
  },
  "generic-openai": {
    name: "Generic OpenAI compatible service",
    description: [t("llm-selection-privacy.generic-openai.description.0")],
    logo: GenericOpenAiLogo,
  },
  cohere: {
    name: "Cohere",
    description: [t("llm-selection-privacy.cohere.description.0")],
    logo: CohereLogo,
  },
  litellm: {
    name: "LiteLLM",
    description: [t("llm-selection-privacy.litellm.description.0")],
    logo: LiteLLMLogo,
  },
  bedrock: {
    name: "AWS Bedrock",
    description: [
      "You model and chat contents are subject to the agreed EULA for AWS and the model provider on aws.amazon.com",
    ],
    logo: AWSBedrockLogo,
  },
  deepseek: {
    name: "DeepSeek",
    description: ["Your model and chat contents are visible to DeepSeek"],
    logo: DeepSeekLogo,
  },
});

export const getVectorDbPrivacy = (t) => ({
  chroma: {
    name: "Chroma",
    description: [
      t("vector-db-privacy.chroma.description.0"),
      t("vector-db-privacy.chroma.description.1"),
    ],
    logo: ChromaLogo,
  },
  pinecone: {
    name: "Pinecone",
    description: [
      t("vector-db-privacy.pinecone.description.0"),
      t("vector-db-privacy.pinecone.description.1"),
    ],
    logo: PineconeLogo,
  },
  qdrant: {
    name: "Qdrant",
    description: [t("vector-db-privacy.qdrant.description.0")],
    logo: QDrantLogo,
  },
  weaviate: {
    name: "Weaviate",
    description: [t("vector-db-privacy.weaviate.description.0")],
    logo: WeaviateLogo,
  },
  milvus: {
    name: "Milvus",
    description: [t("vector-db-privacy.milvus.description.0")],
    logo: MilvusLogo,
  },
  zilliz: {
    name: "Zilliz Cloud",
    description: [t("vector-db-privacy.zilliz.description.0")],
    logo: ZillizLogo,
  },
  astra: {
    name: "AstraDB",
    description: [t("vector-db-privacy.astra.description.0")],
    logo: AstraDBLogo,
  },
  lancedb: {
    name: "LanceDB",
    description: [t("vector-db-privacy.lancedb.description.0")],
    logo: LanceDbLogo,
  },
});

export const getEmbeddingEnginePrivacy = (t) => ({
  native: {
    name: "Default Embedder",
    description: [t("embedding-engine-privacy.native.description.0")],
    logo: AnythingLLMIcon,
  },
  openai: {
    name: "OpenAI",
    description: [
      t("embedding-engine-privacy.openai.description.0"),
      t("embedding-engine-privacy.openai.description.1"),
    ],
    logo: OpenAiLogo,
  },
  azure: {
    name: "Azure OpenAI",
    description: [
      t("embedding-engine-privacy.azure.description.0"),
      t("embedding-engine-privacy.azure.description.1"),
    ],
    logo: AzureOpenAiLogo,
  },
  localai: {
    name: "LocalAI",
    description: [t("embedding-engine-privacy.localai.description.0")],
    logo: LocalAiLogo,
  },
  ollama: {
    name: "Ollama",
    description: [t("embedding-engine-privacy.ollama.description.0")],
    logo: OllamaLogo,
  },
  lmstudio: {
    name: "LMStudio",
    description: [t("embedding-engine-privacy.lmstudio.description.0")],
    logo: LMStudioLogo,
  },
  cohere: {
    name: "Cohere",
    description: [t("embedding-engine-privacy.cohere.description.0")],
    logo: CohereLogo,
  },
  voyageai: {
    name: "Voyage AI",
    description: [t("embedding-engine-privacy.voyageai.description.0")],
    logo: VoyageAiLogo,
  },
  jina: {
    name: "Jina",
    description: [
      "Data sent to Jina's servers is shared according to the terms of service of jina.ai.",
    ],
    logo: JinaLogo,
  },
  litellm: {
    name: "LiteLLM",
    description: [
      "Your document text is only accessible on the server running LiteLLM and to the providers you configured in LiteLLM.",
    ],
    logo: LiteLLMLogo,
  },
  "generic-openai": {
    name: "Generic OpenAI compatible service",
    description: [
      "Data is shared according to the terms of service applicable with your generic endpoint provider.",
    ],
    logo: GenericOpenAiLogo,
  },
});

export default function DataHandling({ setHeader, setForwardBtn, setBackBtn }) {
  const { t } = useTranslation();
  const [llmChoice, setLLMChoice] = useState("openai");
  const [loading, setLoading] = useState(true);
  const [vectorDb, setVectorDb] = useState("pinecone");
  const [embeddingEngine, setEmbeddingEngine] = useState("openai");
  const navigate = useNavigate();

  const LLM_SELECTION_PRIVACY = getLLMSelectionPrivacy(t);
  const VECTOR_DB_PRIVACY = getVectorDbPrivacy(t);
  const EMBEDDING_ENGINE_PRIVACY = getEmbeddingEnginePrivacy(t);

  useEffect(() => {
    setHeader({
      title: t("onboarding.data-handling.title"),
      description: t("onboarding.data-handling.description"),
    });
    setForwardBtn({ showing: true, disabled: false, onClick: handleForward });
    setBackBtn({ showing: false, disabled: false, onClick: handleBack });
    async function fetchKeys() {
      const _settings = await System.keys();
      setLLMChoice(_settings?.LLMProvider || "openai");
      setVectorDb(_settings?.VectorDB || "lancedb");
      setEmbeddingEngine(_settings?.EmbeddingEngine || "openai");

      setLoading(false);
    }
    fetchKeys();
  }, []);

  function handleForward() {
    navigate(paths.onboarding.survey());
  }

  function handleBack() {
    navigate(paths.onboarding.userSetup());
  }

  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center p-20">
        <PreLoader />
      </div>
    );

  return (
    <div className="w-full flex items-center justify-center flex-col gap-y-6 normal-text">
      <div className="p-8 flex flex-col gap-8">
        <div className="flex flex-col gap-y-2 border-b border-zinc-500/50 pb-4">
          <div className="text-base font-bold">
            {t("onboarding.data-handling.llm-label")}
          </div>
          <div className="flex items-center gap-2.5">
            <img
              src={LLM_SELECTION_PRIVACY[llmChoice].logo}
              alt="LLM Logo"
              className="w-8 h-8 rounded"
            />
            <p className="text-sm font-bold">
              {LLM_SELECTION_PRIVACY[llmChoice].name}
            </p>
          </div>
          <ul className="flex flex-col list-disc ml-4">
            {LLM_SELECTION_PRIVACY[llmChoice].description.map((desc) => (
              <li key={desc} className="text-sm">
                {desc}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-y-2 border-b border-zinc-500/50 pb-4">
          <div className="text-base font-bold">
            {t("onboarding.data-handling.embedding-label")}
          </div>
          <div className="flex items-center gap-2.5">
            <img
              src={EMBEDDING_ENGINE_PRIVACY[embeddingEngine].logo}
              alt="LLM Logo"
              className="w-8 h-8 rounded"
            />
            <p className="text-sm font-bold">
              {EMBEDDING_ENGINE_PRIVACY[embeddingEngine].name}
            </p>
          </div>
          <ul className="flex flex-col list-disc ml-4">
            {EMBEDDING_ENGINE_PRIVACY[embeddingEngine].description.map(
              (desc) => (
                <li key={desc} className="text-sm">
                  {desc}
                </li>
              )
            )}
          </ul>
        </div>

        <div className="flex flex-col gap-y-2 pb-4">
          <div className="text-base font-bold">
            {t("onboarding.data-handling.database-lablel")}
          </div>
          <div className="flex items-center gap-2.5">
            <img
              src={VECTOR_DB_PRIVACY[vectorDb].logo}
              alt="LLM Logo"
              className="w-8 h-8 rounded"
            />
            <p className="text-sm font-bold">
              {VECTOR_DB_PRIVACY[vectorDb].name}
            </p>
          </div>
          <ul className="flex flex-col list-disc ml-4">
            {VECTOR_DB_PRIVACY[vectorDb].description.map((desc) => (
              <li key={desc} className="text-sm">
                {desc}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="normal-text text-sm font-medium py-1">
        {t("onboarding.data-handling.reconfigure-option")}
      </p>
    </div>
  );
}
