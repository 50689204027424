import System from "@/models/system";
import { useState, useEffect } from "react";

export default function PerplexityOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Perplexity API Key
        </label>
        <input
          type="password"
          name={`PerplexityApiKey${moduleSuffix}`}
          placeholder="Perplexity API Key"
          defaultValue={
            settings?.[`PerplexityApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          className="modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <PerplexityModelSelection
          settings={settings}
          moduleSuffix={moduleSuffix}
        />
      )}
    </div>
  );
}

function PerplexityModelSelection({ settings, moduleSuffix = "" }) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("perplexity");
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`PerplexityModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true} className="normal-text">
            -- loading available models --
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select
        name={`PerplexityModelPref${moduleSuffix}`}
        required={true}
        className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label="Available Perplexity Models">
            {customModels.map((model) => {
              return (
                <option
                  className="normal-text"
                  key={model.id}
                  value={model.id}
                  selected={
                    settings?.[`PerplexityModelPref${moduleSuffix}`] ===
                    model.id
                  }
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  );
}
