export default function PreLoader({ size = "16" }) {
  return <div className={`h-${size} w-${size} spin`}></div>;
}

export function FullScreenLoader() {
  return (
    <div
      id="preloader"
      className="fixed left-0 top-0 z-999999 flex h-screen w-screen items-center justify-center "
    >
      <div className="spin large"></div>
    </div>
  );
}
