import React, { useState } from "react";
import System from "@/models/system";
import showToast from "@/utils/toast";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";

export default function WebsiteDepthOptions() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    try {
      setLoading(true);
      showToast(t("show-toast.scraping-website"), "info", {
        clear: true,
        autoClose: false,
      });

      const { data, error } = await System.dataConnectors.websiteDepth.scrape({
        url: form.get("url"),
        depth: parseInt(form.get("depth")),
        maxLinks: parseInt(form.get("maxLinks")),
      });

      if (!!error) {
        showToast(error, "error", { clear: true });
        setLoading(false);
        return;
      }

      showToast(
        `Successfully scraped ${data.length} ${pluralize(
          "page",
          data.length
        )}!`,
        "success",
        { clear: true }
      );
      e.target.reset();
      setLoading(false);
    } catch (e) {
      console.error(e);
      showToast(e.message, "error", { clear: true });
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex flex-col w-full px-1 md:pb-6 pb-16">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="w-full flex flex-col py-2">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col pr-10">
                <div className="flex flex-col gap-y-1 mb-4">
                  <label className="normal-text font-bold text-sm flex gap-x-2 items-center">
                    {t("dataConnectors.website-depth.url")}
                  </label>
                  <p className="text-xs font-normal normal-text">
                    {t("dataConnectors.website-depth.url-scrape")}
                  </p>
                </div>
                <input
                  type="url"
                  name="url"
                  placeholder="https://example.com"
                  required={true}
                  autoComplete="off"
                  spellCheck={false}
                  className="dark-input-mdl w-full normal-text  text-sm rounded-md block p-2"
                />
              </div>
              <div className="flex flex-col pr-10">
                <div className="flex flex-col gap-y-1 mb-4">
                  <label className="normal-text font-bold text-sm flex gap-x-2 items-center">
                    {t("dataConnectors.website-depth.depth")}
                  </label>
                  <p className="text-xs font-normal normal-text">
                    {t("dataConnectors.website-depth.child-links")}
                  </p>
                </div>
                <input
                  type="number"
                  name="depth"
                  min="1"
                  max="5"
                  required={true}
                  defaultValue="1"
                  className="dark-input-mdl w-full normal-text  text-sm rounded-md block p-2"
                />
              </div>
              <div className="flex flex-col pr-10">
                <div className="flex flex-col gap-y-1 mb-4">
                  <label className="normal-text font-bold text-sm flex gap-x-2 items-center">
                    {t("dataConnectors.website-depth.max-links")}
                  </label>
                  <p className="text-xs font-normal normal-text">
                    {t("dataConnectors.website-depth.links-scrape")}
                  </p>
                </div>
                <input
                  type="number"
                  name="maxLinks"
                  min="1"
                  required={true}
                  defaultValue="20"
                  className="dark-input-mdl w-full normal-text  text-sm rounded-md block p-2"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 w-full pr-10">
            <button
              type="submit"
              disabled={loading}
              className="primary-bg mt-2 w-full justify-center text-white px-4 py-2 rounded-md text-sm font-bold items-center flex gap-x-2 disabled:cursor-not-allowed"
            >
              {loading
                ? t("dataConnectors.website-depth.scraping")
                : t("dataConnectors.website-depth.submit")}
            </button>
            {loading && (
              <p className="text-xs text-white/50">
                {t("dataConnectors.website-depth.desc-scrap")}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
