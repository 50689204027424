import { ArrowsDownUp } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import Workspace from "../../../../models/workspace";
import System from "../../../../models/system";
import showToast from "../../../../utils/toast";
import Directory from "./Directory";
import WorkspaceDirectory from "./WorkspaceDirectory";
import { useTranslation } from "react-i18next";
import {
  isDocumentDraftingSelected,
  isModuleDocumentDrafting,
} from "@/utils/constants.js";
import { filterFileSearchResults } from "@/components/Modals/ManageWorkspace/Documents/Directory/utils.js";
import PinItemToWorkspace from "@/components/Modals/ManageWorkspace/Documents/WorkspaceDirectory/WorkspaceFileRow/index.jsx";

// OpenAI Cost per token
// ref: https://openai.com/pricing#:~:text=%C2%A0/%201K%20tokens-,Embedding%20models,-Build%20advanced%20search

const MODEL_COSTS = {
  "text-embedding-ada-002": 0.0000001, // $0.0001 / 1K tokens
  "text-embedding-3-small": 0.00000002, // $0.00002 / 1K tokens
  "text-embedding-3-large": 0.00000013, // $0.00013 / 1K tokens
};

export default function DocumentSettings({ workspace, systemSettings }) {
  const { t } = useTranslation();
  const [highlightWorkspace, setHighlightWorkspace] = useState(false);
  const [availableDocs, setAvailableDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpload, setIsUpload] = useState(true);
  const [workspaceDocs, setWorkspaceDocs] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [movedItems, setMovedItems] = useState([]);
  const [embeddingsCost, setEmbeddingsCost] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [documentDraftingSelected, setDocumentDraftingSelected] = useState(
    isModuleDocumentDrafting()
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getDocumentDrafting = async () => {
      setDocumentDraftingSelected(await isDocumentDraftingSelected());
    };
    getDocumentDrafting();
  }, []);

  async function fetchKeys(refetchWorkspace = false, selectAll = false) {
    setLoading(true);

    const currentWorkspace = refetchWorkspace
      ? await Workspace.bySlug(workspace.slug, true)
      : workspace;

    const documentsInWorkspace =
      currentWorkspace.documents.map((doc) => doc.docpath) || [];

    // Documents that are not in the workspace
    const localFiles = await System.localFiles(currentWorkspace.id);

    const availableDocs = {
      ...localFiles,
      items: localFiles.items.map((folder) => {
        if (folder.items && folder.type === "folder") {
          return {
            ...folder,
            items: folder.items.filter((file) => {
              const filePath = file.path;
              const existsInWorkspace = documentsInWorkspace.includes(filePath);
              return (
                file.type === "file" &&
                (!existsInWorkspace || documentDraftingSelected)
              );
            }),
          };
        } else {
          return folder;
        }
      }),
    };

    // Documents that are already in the workspace
    const workspaceDocs = {
      ...localFiles,
      items: localFiles.items.map((folder) => {
        if (folder.items && folder.type === "folder") {
          return {
            ...folder,
            items: folder.items.filter((file) => {
              const filePath = file.path;
              const existsInWorkspace = documentsInWorkspace.includes(filePath);
              return (
                file.type === "file" &&
                (existsInWorkspace || documentDraftingSelected)
              );
            }),
          };
        } else {
          return folder;
        }
      }),
    };

    setAvailableDocs(availableDocs);
    setWorkspaceDocs(workspaceDocs);

    if (selectAll) {
      const filteredFiles = filterFileSearchResults(availableDocs, searchTerm);
      const newSelectedItems = {};
      filteredFiles.forEach((item) => {
        if (item.type === "folder") {
          newSelectedItems[item.name] = true;
          item.items?.forEach((file) => {
            newSelectedItems[file.id] = true;
          });
        } else {
          newSelectedItems[item.id] = true;
        }
      });
      setSelectedItems(newSelectedItems);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchKeys(true, documentDraftingSelected);
  }, []);

  const updateWorkspace = async (e, newMovedItems = []) => {
    if (e) e.preventDefault();

    setLoading(true);
    setLoadingMessage(t("modale.document.loading-message"));
    showToast(t("show-toast.updating-workspace"), "info", { autoClose: false });

    const changesToSend = {
      adds: (newMovedItems && newMovedItems.length > 0
        ? newMovedItems
        : movedItems
      ).map((item) => item.path),
    };

    setSelectedItems({});
    setHasChanges(false);
    setHighlightWorkspace(false);
    await Workspace.modifyEmbeddings(workspace.slug, changesToSend)
      .then((res) => {
        if (!!res.message) {
          showToast(`Error: ${res.message}`, "error", { clear: true });
          return;
        }
        showToast("Workspace updated successfully.", "success", {
          clear: true,
        });
      })
      .catch((error) => {
        showToast(`Workspace update failed: ${error}`, "error", {
          clear: true,
        });
      });

    setMovedItems([]);
    await fetchKeys(true, documentDraftingSelected);
    setLoading(false);
    setLoadingMessage("");
  };

  const moveSelectedItemsToWorkspace = async () => {
    setHighlightWorkspace(false);
    setHasChanges(true);

    const newMovedItems = [];

    for (const itemId of Object.keys(selectedItems)) {
      for (const folder of availableDocs.items) {
        const foundItem = folder.items.find((file) => file.id === itemId);
        if (foundItem) {
          newMovedItems.push({ ...foundItem, folderName: folder.name });
          break;
        }
      }
    }

    let totalTokenCount = 0;
    newMovedItems.forEach((item) => {
      const { cached, token_count_estimate } = item;
      if (!cached) {
        totalTokenCount += token_count_estimate;
      }
    });

    // Do not do cost estimation unless the embedding engine is OpenAi.
    if (systemSettings?.EmbeddingEngine === "openai") {
      const COST_PER_TOKEN =
        MODEL_COSTS[
          systemSettings?.EmbeddingModelPref || "text-embedding-ada-002"
        ];

      const dollarAmount = (totalTokenCount / 1000) * COST_PER_TOKEN;
      setEmbeddingsCost(dollarAmount);
    }

    const combineMovedItems = [...movedItems, ...newMovedItems];
    setMovedItems(combineMovedItems);

    let newAvailableDocs = JSON.parse(JSON.stringify(availableDocs));
    let newWorkspaceDocs = JSON.parse(JSON.stringify(workspaceDocs));

    for (const itemId of Object.keys(selectedItems)) {
      let foundItem = null;
      let foundFolderIndex = null;

      newAvailableDocs.items = newAvailableDocs.items.map(
        (folder, folderIndex) => {
          const remainingItems = folder.items.filter((file) => {
            const match = file.id === itemId;
            if (match) {
              foundItem = { ...file };
              foundFolderIndex = folderIndex;
            }
            return !match;
          });

          return {
            ...folder,
            items: remainingItems,
          };
        }
      );

      if (foundItem) {
        newWorkspaceDocs.items[foundFolderIndex].items.push(foundItem);
      }
    }

    setAvailableDocs(newAvailableDocs);
    setWorkspaceDocs(newWorkspaceDocs);
    setSelectedItems({});

    return combineMovedItems;
  };

  const isDDProcessing = useRef(false);
  if (documentDraftingSelected && hasChanges && !isDDProcessing.current) {
    isDDProcessing.current = true;

    setHasChanges(false);
    setIsUpload(false);

    setTimeout(async function () {
      moveSelectedItemsToWorkspace().then((newMovedItems) => {
        updateWorkspace(null, newMovedItems).then(() => {});
        // Call PinItemToWorkspace for each item
        availableDocs.items.forEach((folder) => {
          folder.items.forEach((item) => {
            PinItemToWorkspace({
              workspace,
              docPath: item.path,
              item,
              documentDraftingSelected,
            });
          });
        });
        isDDProcessing.current = false;
      });
    }, 500);
  }

  return (
    <div className="flex upload-modal -mt-6 z-10 relative">
      <Directory
        files={availableDocs}
        workspaceDocs={workspaceDocs}
        setFiles={setAvailableDocs}
        loading={loading}
        loadingMessage={loadingMessage}
        setLoading={setLoading}
        workspace={workspace}
        fetchKeys={fetchKeys}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isUpload={isUpload}
        setIsUpload={setIsUpload}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        highlightWorkspace={highlightWorkspace}
        setHighlightWorkspace={setHighlightWorkspace}
        moveToWorkspace={moveSelectedItemsToWorkspace}
        setLoadingMessage={setLoadingMessage}
        documentDraftingSelected={documentDraftingSelected}
        saveChanges={updateWorkspace}
        embeddingCosts={embeddingsCost}
        movedItems={movedItems}
        hidden={documentDraftingSelected}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {!documentDraftingSelected && (
        <div className="upload-modal-arrow">
          <ArrowsDownUp className="normal-text text-base font-bold rotate-90 w-11 h-11" />
        </div>
      )}
      {!documentDraftingSelected && (
        <WorkspaceDirectory
          workspace={workspace}
          files={workspaceDocs}
          highlightWorkspace={highlightWorkspace}
          loading={loading}
          loadingMessage={loadingMessage}
          setLoadingMessage={setLoadingMessage}
          setLoading={setLoading}
          fetchKeys={fetchKeys}
          hasChanges={hasChanges}
          saveChanges={updateWorkspace}
          embeddingCosts={embeddingsCost}
          movedItems={movedItems}
        />
      )}
    </div>
  );
}
