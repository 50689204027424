export default function LLMItem({
  name,
  value,
  image,
  description,
  checked,
  onClick,
}) {
  return (
    <button
      onClick={() => onClick(value)}
      className={`flex gap-x-4 w-full p-2 rounded-md hover:cursor-pointer
        ${checked ? "modal-list-Active" : "modal-list-Inactive"}`}
    >
      <img src={image} alt={`${name} logo`} className="w-10 h-10 rounded-md" />
      <div className="text-justify">
        <div className="text-sm font-semibold normal-text">{name}</div>
        <div className="mt-1 text-xs normal-text">{description}</div>
      </div>
    </button>
  );
}
