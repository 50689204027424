import React, { useState } from "react";
import { X } from "@phosphor-icons/react";
import Document from "@/models/document";
import { useParams } from "react-router-dom";

export default function NewFolderModal({ closeModal, files, setFiles }) {
  const { slug } = useParams();
  const [error, setError] = useState(null);
  const [folderName, setFolderName] = useState("");

  const handleCreate = async (e) => {
    e.preventDefault();
    setError(null);
    if (folderName.trim() !== "") {
      const newFolder = {
        name: folderName,
        type: "folder",
        items: [],
      };
      const { success } = await Document.createFolder(folderName, slug);
      if (success) {
        setFiles({
          ...files,
          items: [...files.items, newFolder],
        });
        closeModal();
      } else {
        setError("Failed to create folder");
      }
    }
  };

  return (
    <div className="relative w-full max-w-xl max-h-full">
      <div className="light-modal-bg relative rounded-lg shadow">
        <div className="flex items-start justify-between pt-2 pb-1 px-4 modal-header">
          <h3 className="text-md font-semibold normal-text">
            Create New Folder
          </h3>
          <button
            onClick={closeModal}
            type="button"
            className="transition-all duration-300 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          >
            <X className="normal-text text-lg" />
          </button>
        </div>
        <form onSubmit={handleCreate}>
          <div className="p-6 space-y-6 flex h-full w-full">
            <div className="w-full flex flex-col gap-y-4">
              <div>
                <label
                  htmlFor="folderName"
                  className="block mb-2 text-sm font-medium normal-text"
                >
                  Folder Name
                </label>
                <input
                  name="folderName"
                  type="text"
                  placeholder="Enter folder name"
                  required={true}
                  autoComplete="off"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  className="dark-input-mdl w-full normal-text  text-sm rounded-md block p-2"
                />
              </div>
              {error && <p className="text-red-400 text-sm">Error: {error}</p>}
            </div>
          </div>
          <div className="flex w-full justify-end items-center px-6 space-x-2 modal-footer">
            <button
              onClick={closeModal}
              type="button"
              className="px-4 py-2 normal-text transition-all duration-300 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-bg text-white mx-4 my-3 transition-all duration-300 px-6 py-2 rounded-lg text-sm items-center flex gap-x-2"
            >
              Create Folder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
