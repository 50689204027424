import React, { useState, useEffect, memo } from "react";
import { X } from "@phosphor-icons/react";
import { useParams } from "react-router-dom";
import Workspace from "../../../models/workspace";
import System from "../../../models/system";
import { isMobile } from "react-device-detect";
import useUser from "../../../hooks/useUser";
import DocumentSettings from "./Documents";
import DataConnectors from "./DataConnectors";
import { useTranslation } from "react-i18next";
import {
  getCurrentModule,
  isDocumentDraftingSelected,
  isModuleDocumentDrafting,
} from "@/utils/constants.js";

const noop = () => {};
const ManageWorkspace = ({ hideModal = noop, providedSlug = null }) => {
  const { slug } = useParams();
  const { user } = useUser();
  const [workspace, setWorkspace] = useState(null);
  const [settings, setSettings] = useState({});
  const [selectedTab, setSelectedTab] = useState("documents");

  useEffect(() => {
    async function getSettings() {
      const _settings = await System.keys();
      setSettings(_settings ?? {});
    }
    getSettings();
  }, []);

  useEffect(() => {
    async function fetchWorkspace() {
      const workspace = await Workspace.bySlug(providedSlug ?? slug);
      setWorkspace(workspace);
    }
    fetchWorkspace();
  }, [providedSlug, slug]);

  if (!workspace) return null;

  if (isMobile) {
    return (
      <div className="w-screen h-screen fixed top-0 left-0 flex justify-center items-center z-99">
        <div className="backdrop h-full w-full absolute top-0 z-10" />
        <div className={`absolute max-h-full transition duration-300 z-20`}>
          <div className="relative max-w-lg mx-auto top-main-block rounded-[12px] shadow">
            <div className="p-6">
              <h1 className="normal-text text-lg font-semibold">
                Editing ${workspace.name}
              </h1>
              <p className="normal-text mt-4">
                Editing these settings are only available on a desktop device.
                Please access this page on your desktop to continue.
              </p>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={hideModal}
                  type="button"
                  className="transition-all duration-300 primary-bg px-4 py-2 rounded-md text-white text-sm items-center flex gap-x-2"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-center items-center z-99">
      <div
        className="backdrop h-full w-full absolute top-0 z-10"
        onClick={hideModal}
      />
      <div className="absolute max-h-full w-fit transition duration-300 z-20 md:overflow-y-auto py-5">
        <div className="relative sidebar-block rounded-[12px] shadow">
          <div className="flex items-start justify-between p-2 rounded-t border-gray-500/50 relative">
            <button
              onClick={hideModal}
              type="button"
              className="z-50 text-gray-400 font-bold bg-transparent text-sm p-1.5 ml-auto inline-flex items-center hover:border-white/60 "
            >
              <X className="normal-text text-lg" />
            </button>
          </div>

          {user?.role !== "default" && (
            <ModalTabSwitcher
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}

          {selectedTab === "documents" ? (
            <DocumentSettings workspace={workspace} systemSettings={settings} />
          ) : (
            <DataConnectors workspace={workspace} systemSettings={settings} />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ManageWorkspace);

const ModalTabSwitcher = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <div className="w-full flex justify-center z-10 relative">
      <div className="gap-x-2 flex justify-center -mt-[91px] mb-16 p-1 rounded-xl shadow w-fit">
        <button
          onClick={() => setSelectedTab("documents")}
          className={`px-4 py-2 rounded-[8px] font-semibold primary-bg text-white text-sm ${
            selectedTab === "documents"
              ? "tab-switch-Active"
              : "tab-switch-Inactive"
          }`}
        >
          {t("modale.document.document")}
        </button>
        {(!user || user?.role !== "default") && !isModuleDocumentDrafting() && (
          <button
            onClick={() => setSelectedTab("dataConnectors")}
            className={`px-4 py-2 rounded-[8px] font-semibold primary-bg text-white text-sm ${
              selectedTab === "dataConnectors"
                ? "tab-switch-Active"
                : "tab-switch-Inactive"
            }`}
          >
            {t("modale.connectors.title")}
          </button>
        )}
      </div>
    </div>
  );
};
export function useManageWorkspaceModal() {
  const { user } = useUser();
  const [showing, setShowing] = useState(false);
  const [documentDraftingSelected, setDocumentDraftingSelected] = useState(
    isModuleDocumentDrafting()
  );

  useEffect(() => {
    const getDocumentDrafting = async () => {
      setDocumentDraftingSelected(await isDocumentDraftingSelected());
    };
    getDocumentDrafting();
  }, []);

  function showModal() {
    if (user?.role !== "default" || documentDraftingSelected) {
      setShowing(true);
    }
  }

  function hideModal() {
    setShowing(false);
  }

  useEffect(() => {
    function onEscape(event) {
      if (!showing || event.key !== "Escape") return;
      setShowing(false);
    }

    document.addEventListener("keydown", onEscape);
    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, [showing]);

  return { showing, showModal, hideModal };
}
