import UploadFile from "../UploadFile";
import PreLoader from "@/components/Preloader";
import { memo, useEffect, useState } from "react";
import FolderRow from "./FolderRow";
import System from "@/models/system";
import Document from "@/models/document";
import showToast from "@/utils/toast";
import FolderSelectionPopup from "./FolderSelectionPopup";
import MoveToFolderIcon from "./MoveToFolderIcon";
import { MagnifyingGlass, Plus, Trash } from "@phosphor-icons/react";
import { useModal } from "@/hooks/useModal";
import ModalWrapper from "@/components/ModalWrapper";
import NewFolderModal from "./NewFolderModal";
import debounce from "lodash.debounce";
import { filterFileSearchResults } from "./utils";
import ContextMenu from "./ContextMenu";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function Directory({
  files,
  setFiles,
  loading,
  setLoading,
  workspace,
  fetchKeys,
  selectedItems,
  setSelectedItems,
  isUpload,
  setIsUpload,
  hasChanges,
  setHasChanges,
  setHighlightWorkspace,
  moveToWorkspace,
  setLoadingMessage,
  documentDraftingSelected,
  loadingMessage,
  searchTerm,
  setSearchTerm,
}) {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [amountSelected, setAmountSelected] = useState(0);
  const [showFolderSelection, setShowFolderSelection] = useState(false);
  const {
    isOpen: isFolderModalOpen,
    openModal: openFolderModal,
    closeModal: closeFolderModal,
  } = useModal();
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setAmountSelected(Object.keys(selectedItems).length);
  }, [selectedItems]);

  const deleteFiles = async (event) => {
    event.stopPropagation();
    if (!window.confirm(t("confirm-message.delete-doc"))) {
      return false;
    }

    try {
      const toRemove = [];
      const foldersToRemove = [];

      for (const itemId of Object.keys(selectedItems)) {
        for (const folder of files.items) {
          const foundItem = folder.items.find((file) => file.id === itemId);
          if (foundItem) {
            toRemove.push(foundItem.path);
            break;
          }
        }
      }
      for (const folder of files.items) {
        if (
          folder.name === "custom-documents" ||
          folder.name === "document-drafting"
        ) {
          continue;
        }
        if (isSelected(folder.id, folder)) {
          foldersToRemove.push(folder.name);
        }
      }

      setLoading(true);
      setLoadingMessage(
        `Removing ${toRemove.length} documents and ${foldersToRemove.length} folders. Please wait.`
      );
      await System.deleteDocuments(toRemove);
      for (const folderName of foldersToRemove) {
        await System.deleteFolder(folderName, slug);
      }

      await fetchKeys(true);
      setSelectedItems({});
    } catch (error) {
      console.error("Failed to delete files and folders:", error);
    } finally {
      setLoading(false);
      setSelectedItems({});
    }
  };

  const toggleSelection = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = { ...prevSelectedItems };
      if (item.type === "folder") {
        // select all files in the folder
        if (newSelectedItems[item.name]) {
          delete newSelectedItems[item.name];
          item.items.forEach((file) => delete newSelectedItems[file.id]);
        } else {
          newSelectedItems[item.name] = true;
          item.items.forEach((file) => (newSelectedItems[file.id] = true));
        }
      } else {
        // single file selections
        if (newSelectedItems[item.id]) {
          delete newSelectedItems[item.id];
        } else {
          newSelectedItems[item.id] = true;
        }
      }
      return newSelectedItems;
    });
    return true;
  };

  // check if item is selected based on selectedItems state
  const isSelected = (id, item) => {
    if (item && item.type === "folder") {
      if (!selectedItems[item.name]) {
        return false;
      }
      let isSave = false;
      let every = item.items.every(function (file) {
        if (documentDraftingSelected) {
          if (isUpload && !hasChanges && !file.cached) {
            file.cached = true;
            isSave = true;
          }
        }
        return selectedItems[file.id];
      });
      if (isSave) {
        setHasChanges(isSave);
      }
      return every;
    }
    return !!selectedItems[id];
  };

  const moveToFolder = async (folder) => {
    const toMove = [];
    for (const itemId of Object.keys(selectedItems)) {
      for (const currentFolder of files.items) {
        const foundItem = currentFolder.items.find(
          (file) => file.id === itemId
        );
        if (foundItem) {
          toMove.push({ ...foundItem, folderName: currentFolder.name });
          break;
        }
      }
    }
    setLoading(true);
    setLoadingMessage(`Moving ${toMove.length} documents. Please wait.`);
    const { success, message } = await Document.moveToFolder(
      toMove,
      folder.name,
      slug
    );
    if (!success) {
      showToast(`Error moving files: ${message}`, "error");
      setLoading(false);
      return;
    }

    if (success && message) {
      // show info if some files were not moved due to being embedded
      showToast(message, "info");
    } else {
      showToast(`Successfully moved ${toMove.length} documents.`, "success");
    }
    await fetchKeys(true);
    setSelectedItems({});
    setLoading(false);
  };
  const handleSearch = debounce((e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  }, 500);

  const filteredFiles = filterFileSearchResults(files, searchTerm);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({ visible: true, x: event.clientX, y: event.clientY });
  };
  const closeContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  const updateFolderName = (oldName, newName) => {
    setFiles((prevFiles) => {
      const updatedItems = prevFiles.items.map((item) =>
        item.name === oldName ? { ...item, name: newName } : item
      );
      return { ...prevFiles, items: updatedItems };
    });
  };

  return (
    <div className="px-8 pb-8" onContextMenu={handleContextMenu}>
      <div className="flex flex-col gap-y-5">
        <div className="flex items-center justify-between w-[560px] relative">
          <h3 className="normal-text text-base font-bold">
            {t("modale.document.title")}
          </h3>
          {!documentDraftingSelected && (
            <div className="relative">
              <input
                type="search"
                placeholder={t("modale.document.search")}
                onChange={handleSearch}
                className="dark-input-mdl search-input normal-text text-sm rounded-lg pl-9 pr-2.5 py-2 w-[250px] h-[32px]"
              />
              <MagnifyingGlass
                size={14}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 normal-text"
                weight="bold"
              />
            </div>
          )}
          {!documentDraftingSelected && (
            <button
              className="flex items-center gap-x-2 cursor-pointer px-[14px] py-[7px] -mr-[14px] rounded-lg"
              onClick={openFolderModal}
            >
              <Plus size={15} weight="bold" className="normal-text" />
              <div className="normal-text text-xs font-bold leading-[18px]">
                {t("modale.document.folder")}
              </div>
            </button>
          )}
        </div>

        <div className="relative w-[560px] h-[310px] deep-xl-input rounded-lg overflow-hidden">
          <div className="absolute top-0 left-0 right-0 z-10 file-list-header rounded-t-lg normal-text font-bold text-xs grid grid-cols-12 py-2 px-8 border-b border-white/20 shadow-lg ">
            <p className="col-span-6">{t("modale.document.name")}</p>
          </div>

          <div className="overflow-y-auto h-full pt-8">
            {loading ? (
              <div className="w-full h-full flex items-center justify-center flex-col gap-y-5">
                <PreLoader />
                <p className="normal-text text-sm font-semibold animate-pulse text-center w-1/3">
                  {loadingMessage}
                </p>
              </div>
            ) : filteredFiles.length > 0 ? (
              filteredFiles.map(
                (item, index) =>
                  item.type === "folder" && (
                    <FolderRow
                      key={index}
                      item={item}
                      onRowClick={() => toggleSelection(item)}
                      autoExpanded={index === 0}
                      toggleSelection={toggleSelection}
                      isSelected={isSelected}
                      selected={isSelected(
                        item.id,
                        item.type === "folder" ? item : null
                      )}
                      updateFolderName={updateFolderName}
                      slug={slug}
                    />
                  )
              )
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <p className="normal-text text-opacity-40 text-sm font-medium">
                  {t("modale.document.empty")}
                </p>
              </div>
            )}
          </div>
          {amountSelected !== 0 && (
            <div className="absolute bottom-[10px] left-0 right-0 flex justify-center pointer-events-non gap-x-2 px-2 pointer-events-auto">
              {!documentDraftingSelected && (
                <>
                  <button
                    onClick={moveToWorkspace}
                    onMouseEnter={() => setHighlightWorkspace(true)}
                    onMouseLeave={() => setHighlightWorkspace(false)}
                    className="primary-bg text-white border border-white h-[30px] px-2.5 rounded-md text-xs"
                  >
                    {t("modale.document.move-workspace")}
                  </button>

                  <button
                    onClick={() => setShowFolderSelection(!showFolderSelection)}
                    className="primary-bg text-white border h-[30px] px-2.5 rounded-md text-xs"
                  >
                    <MoveToFolderIcon className=" group-hover:text-white" />
                  </button>
                  {showFolderSelection && (
                    <FolderSelectionPopup
                      folders={files.items.filter(
                        (item) => item.type === "folder"
                      )}
                      onSelect={moveToFolder}
                      onClose={() => setShowFolderSelection(false)}
                    />
                  )}
                </>
              )}
              <button
                onClick={deleteFiles}
                className="primary-bg text-white border h-[30px] px-2.5 rounded-md text-xs"
              >
                <Trash size={18} weight="bold" />
              </button>
            </div>
          )}
        </div>

        <UploadFile
          workspace={workspace}
          fetchKeys={fetchKeys}
          loading={loading}
          setLoading={setLoading}
          setLoadingMessage={setLoadingMessage}
          isUpload={isUpload}
          setIsUpload={setIsUpload}
          documentDraftingSelected={documentDraftingSelected}
        />
      </div>
      <ModalWrapper isOpen={isFolderModalOpen} zIndex={100}>
        <NewFolderModal
          closeModal={closeFolderModal}
          files={files}
          setFiles={setFiles}
        />
      </ModalWrapper>
      <ContextMenu
        contextMenu={contextMenu}
        closeContextMenu={closeContextMenu}
        files={files}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
}

export default memo(Directory);
