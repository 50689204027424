import { CloudArrowUp } from "@phosphor-icons/react";
import { useEffect, useState, useCallback } from "react";
import showToast from "../../../../../utils/toast";
import System from "../../../../../models/system";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import FileUploadProgress from "./FileUploadProgress";
import Workspace from "../../../../../models/workspace";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

export default function UploadFile({
  workspace,
  fetchKeys,
  loading,
  setLoading,
  setLoadingMessage,
  setIsUpload,
  documentDraftingSelected,
}) {
  const [ready, setReady] = useState(false);
  const [files, setFiles] = useState([]);
  const [fetchingUrl, setFetchingUrl] = useState(false);
  const [failedUploads, setFailedUploads] = useState([]);

  const { t } = useTranslation();

  const handleSendLink = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingMessage("Scraping link...");
    setFetchingUrl(true);
    const formEl = e.target;
    const form = new FormData(formEl);
    const { response, data } = await Workspace.uploadLink(
      workspace.slug,
      form.get("link")
    );
    if (!response.ok) {
      showToast(`Error uploading link: ${data.error}`, "error");
    } else {
      fetchKeys(true, documentDraftingSelected);
      showToast("Link uploaded successfully", "success");
      formEl.reset();
    }
    setLoading(false);
    setFetchingUrl(false);
    setIsUpload(true);
  };

  // Don't spam fetchKeys, wait 1s between calls at least.
  const handleUploadSuccess = debounce(
    () => fetchKeys(true, documentDraftingSelected),
    1000
  );

  const handleUploadError = useCallback((file) => {
    setFailedUploads((prev) => [
      ...prev,
      { fileName: file.originalname, error: file.error },
    ]);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles, rejections) => {
      setFailedUploads([]);
      const existingFiles = files.map((f) => `${f.file.name}-${f.file.size}`);

      const newAccepted = acceptedFiles
        .filter((file) => !existingFiles.includes(`${file.name}-${file.size}`))
        .map((file) => ({
          uid: uuidv4(),
          file,
        }));
      const newRejected = rejections.map((file) => ({
        uid: uuidv4(),
        file: file.file,
        rejected: true,
        reason: file.errors[0].code,
      }));
      setFiles((prevFiles) => [...prevFiles, ...newAccepted, ...newRejected]);
    },
    [files]
  );

  useEffect(() => {
    async function checkProcessorOnline() {
      const online = await System.checkDocumentProcessorOnline();
      setReady(online);
    }
    checkProcessorOnline();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: !ready,
  });

  return (
    <div>
      <div
        className={`w-[560px] upload-main-input rounded-2xl loading-box p-3 ${
          ready ? "cursor-pointer" : "cursor-not-allowed"
        } hover:opacity-80`}
        {...getRootProps()}
      >
        <input {...getInputProps()} accept="application/pdf" />
        {ready === false ? (
          <div className="flex flex-col items-center justify-center h-full">
            <CloudArrowUp className="w-8 h-8 normal-text black-normal-text" />
            <div className="normal-text black-normal-text text-opacity-80 text-sm font-semibold py-1">
              {t("modale.document.doc-processor")}
            </div>
            <div className="normal-text black-normal-text text-opacity-60 text-xs font-medium py-1 px-20 text-center">
              {t("modale.document.processor-offline")}
            </div>
          </div>
        ) : files.length === 0 ? (
          <div className="flex flex-col items-center justify-center">
            <CloudArrowUp className="w-8 h-8 normal-text" />
            <div className="normal-text black-normal-text text-sm font-semibold py-1">
              {t("modale.document.drag-drop")}
            </div>
            <div className="normal-text black-normal-text text-opacity-60 text-xs font-medium py-1">
              {t("modale.document.supported-files")}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2 overflow-auto max-h-[180px] p-1 overflow-y-scroll no-scroll">
            {files.map(function (file) {
              return (
                <FileUploadProgress
                  key={file.uid}
                  file={file.file}
                  uuid={file.uid}
                  setFiles={setFiles}
                  slug={workspace.slug}
                  rejected={file?.rejected}
                  reason={file?.reason}
                  onUploadSuccess={handleUploadSuccess}
                  onUploadError={handleUploadError}
                  loading={loading}
                  setLoading={setLoading}
                  setLoadingMessage={setLoadingMessage}
                  setIsUpload={setIsUpload}
                />
              );
            })}
          </div>
        )}
        {failedUploads.length > 0 && (
          <div className="mt-4">
            <h3 className="text-red-500 font-semibold mb-2">
              {t("modale.document.failed-uploads")}
            </h3>
            <div className="max-h-[100px] overflow-y-auto">
              {failedUploads.map((failedFile, index) => (
                <div key={index} className="text-sm text-red-400 mb-1">
                  {index + 1}- {failedFile.fileName}: {failedFile.error}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {!documentDraftingSelected && (
        <div>
          <div className="text-center normal-text text-opacity-50 text-xs font-medium w-[560px] py-2">
            {t("modale.document.submit-link")}
          </div>
          <form
            onSubmit={handleSendLink}
            className="flex justify-between gap-x-1"
          >
            <input
              disabled={fetchingUrl}
              name="link"
              type="url"
              placeholder={"https://example.com"}
              autoComplete="off"
              className="dark-input-mdl disabled:bg-gray-600 disabled:text-white normal-text text-sm rounded-lg block w-3/4 p-2.5"
            />
            <button
              disabled={fetchingUrl}
              type="submit"
              className="primary-bg disabled:cursor-not-allowed text-[13px] disabled:text-slate-300 text-white py-2.5 px-6 rounded-md"
            >
              {fetchingUrl
                ? t("modale. justify-betweening")
                : t("modale.document.fetch")}
            </button>
          </form>
          <div className="mt-6 text-left normal-text text-xs w-[560px]">
            {t("modale.document.file-desc")}
          </div>
        </div>
      )}
    </div>
  );
}
