import { useEffect, useState } from "react";
import { Flask } from "@phosphor-icons/react";
import System from "@/models/system";

export default function NativeLLMOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="flex flex-col md:flex-row md:items-center gap-x-2 text-white mb-4 deep-xl-input w-fit rounded-lg px-4 py-2">
        <div className="gap-x-2 flex items-center">
          <Flask size={18} />
          <p className="text-sm md:text-base">
            Using a locally hosted LLM is experimental. Use with caution.
          </p>
        </div>
      </div>
      <div className="w-full flex items-center gap-4">
        <NativeModelSelection settings={settings} moduleSuffix={moduleSuffix} />
      </div>
    </div>
  );
}

function NativeModelSelection({ settings, moduleSuffix = "" }) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("native-llm", null, null);
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Model Selection
        </label>
        <select
          name={`NativeLLMModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true} className="text-black">
            -- waiting for models --
          </option>
        </select>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Model Selection
        </label>
        <select
          name={`NativeLLMModelPref${moduleSuffix}`}
          required={true}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
        >
          {customModels.length > 0 && (
            <optgroup label="Your loaded models">
              {customModels.map((model) => {
                return (
                  <option
                    key={model.id}
                    value={model.id}
                    selected={
                      settings?.[`NativeLLMModelPref${moduleSuffix}`] ===
                      model.id
                    }
                    className="text-black"
                  >
                    {model.id}
                  </option>
                );
              })}
            </optgroup>
          )}
        </select>
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Token context window
        </label>
        <input
          type="number"
          name={`NativeLLMTokenLimit${moduleSuffix}`}
          placeholder="4096"
          min={1}
          onScroll={(e) => e.target.blur()}
          defaultValue={settings?.[`NativeLLMTokenLimit${moduleSuffix}`]}
          required={true}
          autoComplete="off"
          className="dark-input-mdl text-white  text-sm rounded-lg   block w-full p-2.5"
        />
      </div>
    </>
  );
}
