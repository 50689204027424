import { useState, useEffect } from "react";
import System from "@/models/system";

export default function OpenAiOptions({ settings, moduleSuffix = "" }) {
  const [inputValue, setInputValue] = useState(
    settings?.[`OpenAiKey${moduleSuffix}`]
  );
  const [openAIKey, setOpenAIKey] = useState(
    settings?.[`OpenAiKey${moduleSuffix}`]
  );
  const [selectedModel, setSelectedModel] = useState(
    settings?.[`OpenAiModelPref${moduleSuffix}`] || ""
  );

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
    // You might want to handle additional logic here, such as updating settings
  };

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-full">
        <label className="normal-text text-sm font-semibold block mb-4">
          API Key
        </label>
        <input
          type="password"
          name={`OpenAiKey${moduleSuffix}`}
          placeholder="OpenAI API Key"
          defaultValue={
            settings?.[`OpenAiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => setOpenAIKey(inputValue)}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-1.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <OpenAIModelSelection
          settings={settings}
          apiKey={openAIKey}
          moduleSuffix={moduleSuffix}
          selectedModel={selectedModel}
          onModelChange={handleModelChange}
        />
      )}
    </div>
  );
}

function OpenAIModelSelection({
  apiKey,
  moduleSuffix = "",
  selectedModel,
  onModelChange,
}) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      try {
        const { models } = await System.customModels(
          "openai",
          typeof apiKey === "boolean" ? null : apiKey
        );

        if (models?.length > 0) {
          const modelsByOrganization = models.reduce((acc, model) => {
            acc[model.organization] = acc[model.organization] || [];
            acc[model.organization].push(model);
            return acc;
          }, {});
          setGroupedModels(modelsByOrganization);
        } else {
          setGroupedModels({});
        }
      } catch (error) {
        console.error("Error fetching models:", error);
        setGroupedModels({});
      }
      setLoading(false);
    }
    if (apiKey) {
      findCustomModels();
    } else {
      setGroupedModels({});
      setLoading(false);
    }
  }, [apiKey]);

  if (loading) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`OpenAiModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true} className="normal-text">
            -- loading available models --
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select
        name={`OpenAiModelPref${moduleSuffix}`}
        required={true}
        value={selectedModel}
        onChange={onModelChange}
        className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {/* Divider for clarity */}
        {Object.keys(groupedModels).length > 0 && (
          <option disabled className="text-gray-500">
            ──────────
          </option>
        )}

        {/* Dynamically Fetched Models */}
        {Object.keys(groupedModels)
          .sort()
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option key={model.id} value={model.id} className="normal-text">
                  {model.name}
                </option>
              ))}
              {/* Hardcoded API Option */}
              <option value="chatgpt-4o-latest" className="normal-text">
                chatgpt-4o-latest
              </option>
            </optgroup>
          ))}
      </select>
    </div>
  );
}
