import { ArrowSquareOut, Info } from "@phosphor-icons/react";
import { AWS_REGIONS } from "./regions";

export default function AwsBedrockLLMOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col">
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <div className="flex flex-col md:flex-row md:items-center gap-x-2 text-white mb-4 primary-bg w-fit rounded-lg px-4 py-2">
          <div className="gap-x-2 flex items-center">
            <Info size={40} />
            <p className="text-base">
              You should use a properly defined IAM user for inferencing.
              <br />
              <a
                href="https://docs.anythingllm.com/setup/llm-configuration/cloud/aws-bedrock"
                target="_blank"
                className="underline flex gap-x-1 items-center"
              >
                Read more on how to use AWS Bedrock in AnythingLLM
                <ArrowSquareOut size={14} />
              </a>
            </p>
          </div>
        </div>
      )}

      <div className="w-full flex items-center gap-[36px] my-1.5">
        <div className="flex flex-col w-60">
          <label className="normal-text text-sm font-semibold block mb-3">
            AWS Bedrock IAM Access ID
          </label>
          <input
            className="dark-input-mdl normal-text text-sm rounded-lg outline-none block w-full p-2"
            type="password"
            name={`AwsBedrockLLMAccessKeyId${moduleSuffix}`}
            placeholder="AWS Bedrock IAM User Access ID"
            defaultValue={
              settings?.[`AwsBedrockLLMAccessKeyId${moduleSuffix}`]
                ? "*".repeat(20)
                : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
        <div className="flex flex-col w-60">
          <label className="normal-text text-sm font-semibold block mb-3">
            AWS Bedrock IAM Access Key
          </label>
          <input
            className="dark-input-mdl normal-text text-sm rounded-lg outline-none block w-full p-2"
            type="password"
            name={`AwsBedrockLLMAccessKey${moduleSuffix}`}
            placeholder="AWS Bedrock IAM User Access Key"
            defaultValue={
              settings?.[`AwsBedrockLLMAccessKey${moduleSuffix}`]
                ? "*".repeat(20)
                : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
        <div className="flex flex-col w-60">
          <label className="normal-text text-sm font-semibold block mb-3">
            AWS region
          </label>
          <select
            name={`AwsBedrockLLMRegion${moduleSuffix}`}
            defaultValue={
              settings?.[`AwsBedrockLLMRegion${moduleSuffix}`] || "us-west-2"
            }
            required={true}
            className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2"
          >
            {AWS_REGIONS.map((region) => {
              return (
                <option key={region.code} value={region.code}>
                  {region.name} ({region.code})
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="w-full flex items-center gap-[36px] my-1.5">
        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <>
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-3">
                Model ID
              </label>
              <input
                className="dark-input-mdl normal-text text-sm rounded-lg outline-none block w-full p-2"
                type="text"
                name={`AwsBedrockLLMModel${moduleSuffix}`}
                placeholder="Model id from AWS eg: meta.llama3.1-v0.1"
                defaultValue={settings?.[`AwsBedrockLLMModel${moduleSuffix}`]}
                required={true}
                autoComplete="off"
                spellCheck={false}
              />
            </div>
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-3">
                Model context window
              </label>
              <input
                className="dark-input-mdl normal-text text-sm rounded-lg outline-none block w-full p-2"
                type="number"
                name={`AwsBedrockLLMTokenLimit${moduleSuffix}`}
                placeholder="Content window limit (eg: 4096)"
                min={1}
                onScroll={(e) => e.target.blur()}
                defaultValue={
                  settings?.[`AwsBedrockLLMTokenLimit${moduleSuffix}`]
                }
                required={true}
                autoComplete="off"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
