import { useState, useEffect } from "react";
import System from "@/models/system";

export default function GroqAiOptions({ settings, moduleSuffix = "" }) {
  const [inputValue, setInputValue] = useState(
    settings?.[`GroqApiKey${moduleSuffix}`]
  );
  const [apiKey, setApiKey] = useState(settings?.[`GroqApiKey${moduleSuffix}`]);

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Groq API Key
        </label>
        <input
          type="password"
          name={`GroqApiKey${moduleSuffix}`}
          className="dark-input-mdl w-full normal-text  text-sm rounded-lg block w-full p-2.5"
          placeholder="Groq API Key"
          defaultValue={
            settings?.[`GroqApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => setApiKey(inputValue)}
        />
      </div>

      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <GroqAIModelSelection
          settings={settings}
          apiKey={apiKey}
          moduleSuffix={moduleSuffix}
        />
      )}
    </div>
  );
}

function GroqAIModelSelection({ apiKey, settings, moduleSuffix = "" }) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!apiKey) {
        setCustomModels([]);
        setLoading(true);
        return;
      }

      try {
        setLoading(true);
        const { models } = await System.customModels("groq", apiKey);
        setCustomModels(models || []);
      } catch (error) {
        console.error("Failed to fetch custom models:", error);
        setCustomModels([]);
      } finally {
        setLoading(false);
      }
    }
    findCustomModels();
  }, [apiKey]);

  if (loading) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-3">
          Chat Model Selection
        </label>
        <select
          name={`GroqModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true} className="normal-text">
            --loading available models--
          </option>
        </select>
        <p className="text-xs leading-[18px] font-base normal-text text-opacity-60 mt-2">
          Enter a valid API key to view all available models for your account.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-3">
        Chat Model Selection
      </label>
      <select
        name={`GroqModelPref${moduleSuffix}`}
        required={true}
        className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label="Available models">
            {customModels.map((model) => {
              return (
                <option
                  className="normal-text"
                  key={model.id}
                  value={model.id}
                  selected={
                    settings?.[`GroqModelPref${moduleSuffix}`] === model.id
                  }
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
      <p className="text-xs leading-[18px] font-base normal-text text-opacity-60 mt-2">
        Select the GroqAI model you want to use for your conversations.
      </p>
    </div>
  );
}
