// src/main.jsx
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "@/App.jsx";
import "./index.css";
import { ThemeProvider } from "./context";

const renderApp = () => {
  const AppContent = (
    <ThemeProvider>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  );

  ReactDOM.createRoot(document.getElementById("root")).render(
    process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_DEBUG === "true" ? (
      <React.StrictMode>{AppContent}</React.StrictMode>
    ) : (
      AppContent
    )
  );
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", renderApp);
} else {
  renderApp();
}
