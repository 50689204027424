import PreLoader from "@/components/Preloader";
import { dollarFormat } from "@/utils/numbers";
import WorkspaceFileRow from "./WorkspaceFileRow";
import { memo, useEffect, useState } from "react";
import ModalWrapper from "@/components/ModalWrapper";
import { Eye, PushPin } from "@phosphor-icons/react";
import { SEEN_DOC_PIN_ALERT, SEEN_WATCH_ALERT } from "@/utils/constants";
import paths from "@/utils/paths";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Workspace from "@/models/workspace.js";
import showToast from "@/utils/toast.js";
import { useTranslation } from "react-i18next";
import useUser from "@/hooks/useUser";

function WorkspaceDirectory({
  workspace,
  files,
  highlightWorkspace,
  loading,
  loadingMessage,
  setLoadingMessage,
  setLoading,
  fetchKeys,
  hasChanges,
  saveChanges,
  embeddingCosts,
  movedItems,
  documentDraftingSelected,
}) {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div className="px-8">
        <div className="flex items-center justify-start w-[560px]">
          <h3 className="normal-text trnsf text-base font-bold ml-5">
            {workspace.name}
          </h3>
        </div>
        <div className="relative w-[560px] h-[445px] deep-xl-input rounded-2xl mt-5">
          <div className="normal-text text-xs grid grid-cols-12 py-2 px-8">
            <p className="col-span-2" />
          </div>
          <div className="w-full h-full flex items-center justify-center flex-col gap-y-5">
            <PreLoader />
            <p className="normal-text text-sm font-semibold animate-pulse text-center w-1/3">
              {loadingMessage}
            </p>
          </div>
        </div>
      </div>
    );
  }

  let isPRDAll = false;
  if (files.items && files.items.length > 1) {
    isPRDAll = files.items
      .slice(1)
      .every(
        (folder) =>
          folder.items.length > 0 &&
          folder.items.every(
            (doc) => doc?.pdrWorkspaces?.includes(workspace.id) || false
          )
      );
  }

  //For now we don't show embeddingCosts
  const showEmbeddingCosts = () => {
    return (
      embeddingCosts && (
        <div className="normal-text">
          <p className="text-sm font-semibold">
            `Estimated Cost: $
            {embeddingCosts < 0.01 ? `< $0.01` : dollarFormat(embeddingCosts)}`
          </p>
          <p className="mt-2 text-xs italic">{t("modale.document.cost")}</p>
        </div>
      )
    );
  };

  return (
    <>
      <div className="px-8">
        <div className="flex items-center justify-start w-[560px]">
          <h3 className="normal-text text-base font-bold ml-4">
            {workspace.name}
          </h3>
        </div>
        <div
          className={`relative w-[560px] h-[445px] rounded-lg mt-5 overflow-y-auto border-4 ${
            highlightWorkspace ? "border-red-200" : "border-transparent"
          }`}
        >
          <div className="normal-text font-bold text-xs grid grid-cols-12 py-2 px-8 sticky top-0 z-10 file-list-header">
            <p className="w-auto col-span-10 flex gap-x-[4px] items-center relative pr-8">
              {t("modale.document.name")}
            </p>
            <div className="col-span-2 flex justify-center items-center">
              <div className="flex gap-x-2 items-center">
                <div className="hidden"></div>
                <PDRItemsToWorkspace
                  workspace={workspace}
                  refreshList={fetchKeys}
                  isPRDAll={isPRDAll}
                />
                <div></div>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex flex-col z-0 deep-xl-input">
            {Object.values(files.items).some(
              (folder) => folder.items.length > 0
            ) || movedItems.length > 0 ? (
              <>
                {files.items.map((folder) =>
                  folder.items.map((item, index) => (
                    <WorkspaceFileRow
                      key={index}
                      item={item}
                      folderName={folder.name}
                      workspace={workspace}
                      setLoading={setLoading}
                      setLoadingMessage={setLoadingMessage}
                      fetchKeys={fetchKeys}
                      hasChanges={hasChanges}
                      movedItems={movedItems}
                      documentDraftingSelected={documentDraftingSelected}
                    />
                  ))
                )}
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <p className="normal-text text-opacity-40 text-sm font-medium">
                  {t("modale.document.empty")}
                </p>
              </div>
            )}
          </div>
        </div>
        {hasChanges && (
          <div className="flex items-center justify-end py-6">
            <button
              onClick={saveChanges}
              className="primary-bg px-5 py-2.5 rounded-lg text-white text-sm items-center flex gap-x-2"
            >
              {t("modale.document.save-embed")}
            </button>
          </div>
        )}
      </div>
      <PinAlert />
      <DocumentWatchAlert />
    </>
  );
}

const PinAlert = memo(() => {
  const { user } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  function dismissAlert() {
    setShowAlert(false);
    window.localStorage.setItem(SEEN_DOC_PIN_ALERT, "1");
    window.removeEventListener("pinned_document", handlePinEvent);
  }

  function handlePinEvent() {
    if (window?.localStorage?.getItem(SEEN_DOC_PIN_ALERT)) return;
    setShowAlert(true);
  }

  useEffect(() => {
    if (!window || !!window?.localStorage?.getItem(SEEN_DOC_PIN_ALERT)) return;
    window?.addEventListener("pinned_document", handlePinEvent);
  }, []);

  return (
    <ModalWrapper isOpen={showAlert} noPortal={true}>
      <div className="relative w-full max-w-2xl max-h-full left-block">
        <div className="relative rounded-lg shadow-xl">
          <div className="flex items-start justify-between py-3 px-4 border-b rounded-t border-[#c0dbe6]">
            <div className="flex items-center gap-2">
              <PushPin className="text-red-400 w-4 h-4" weight="fill" />
              {(!user || user?.role !== "default") && (
                <h3 className="text-md font-semibold text-white">
                  What is document pinning?
                </h3>
              )}
            </div>
          </div>
          {(!user || user?.role !== "default") && (
            <div className="w-full p-6 text-sm flex flex-col gap-y-2 text-white/80">
              <p>
                When you <b>pin</b> a document the platform we will inject the
                entire content of the document into your prompt window for your
                LLM to fully comprehend.
              </p>
              <p>
                This works best with <b>large-context models</b> or small files
                that are critical to its knowledge-base.
              </p>
              <p>
                If you are not getting the answers you desire by default then
                pinning is a great way to get higher quality answers in a click.
              </p>
            </div>
          )}

          <div className="flex w-full justify-end items-center px-2 space-x-2 border-t border-[#c0dbe6] rounded-b">
            <button disabled={true} className="invisible" />
            <button
              onClick={dismissAlert}
              className="text-white mx-4 my-3 px-6 py-2.5 rounded-lg text-sm items-center border-slate-200 border hover:bg-historical-msg-user/40"
            >
              Okay, got it
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
});

PinAlert.displayName = "PinAlert";

const PDRItemsToWorkspace = memo(({ workspace, refreshList, isPRDAll }) => {
  const [isPDR, setPDR] = useState(isPRDAll);
  const [hover, setHover] = useState(false);
  const pdrEvent = new CustomEvent("pdr_document");

  useEffect(() => {
    setPDR(isPRDAll);
  }, [isPRDAll]);

  const updatePDRStatuses = async () => {
    try {
      if (!isPDR) window.dispatchEvent(pdrEvent);
      const success = await Workspace.setPDRForDocument(
        workspace.slug,
        null,
        !isPDR
      );

      if (!success) {
        showToast(
          `Failed to ${!isPDR ? "mark" : "unmark"} as PDR documents.`,
          "error",
          {
            clear: true,
          }
        );
        return;
      }

      showToast(
        `All documents ${
          !isPDR ? "added to" : "removed from"
        } Parent Document Retrieval`,
        "success",
        { clear: true }
      );
      setPDR(!isPDR);

      // Refresh the list after a successful update
      refreshList();
    } catch (error) {
      showToast(`Failed to PDR all documents. ${error.message}`, "error", {
        clear: true,
      });
    }
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="flex gap-x-2 items-center hover:bg-main-gradient p-[2px] rounded ml-2"
    >
      <input
        type="checkbox"
        id={`pdr`}
        checked={isPRDAll}
        onChange={updatePDRStatuses}
        className="outline-none text-base font-bold flex-shrink-0 cursor-pointer"
        data-tooltip-id={`pdr`}
        data-tooltip-content={
          isPDR
            ? "Removed all documents from Parent Document Retrieval"
            : "Added all documents to Parent Document Retrieval"
        }
      />
      <Tooltip
        id={`pin`}
        place="bottom"
        delayShow={300}
        className="tooltip invert !text-xs"
      />
    </div>
  );
});

PDRItemsToWorkspace.displayName = "PDRItemsToWorkspace";

const DocumentWatchAlert = memo(() => {
  const [showAlert, setShowAlert] = useState(false);
  function dismissAlert() {
    setShowAlert(false);
    window.localStorage.setItem(SEEN_WATCH_ALERT, "1");
    window.removeEventListener(handlePinEvent);
  }

  function handlePinEvent() {
    if (!!window?.localStorage?.getItem(SEEN_WATCH_ALERT)) return;
    setShowAlert(true);
  }

  useEffect(() => {
    if (!window || !!window?.localStorage?.getItem(SEEN_WATCH_ALERT)) return;
    window?.addEventListener("watch_document_for_changes", handlePinEvent);
  }, []);

  return (
    <ModalWrapper isOpen={showAlert} noPortal={true}>
      <div className="relative w-full max-w-2xl max-h-full">
        <div className="relative bg-main-gradient rounded-lg shadow">
          <div className="flex items-start justify-between p-4 rounded-t border-gray-500/50">
            <div className="flex items-center gap-2">
              <Eye
                className="text-yellow-600 text-lg w-6 h-6"
                weight="regular"
              />
              <h3 className="text-xl font-semibold text-white">
                What does watching a document do?
              </h3>
            </div>
          </div>
          <div className="w-full p-6 text-white text-md flex flex-col gap-y-2">
            <p>
              When you <b>watch</b> a document in AnythingLLM we will{" "}
              <i>automatically</i> sync your document content from it's original
              source on regular intervals. This will automatically update the
              content in every workspace where this file is managed.
            </p>
            <p>
              This feature currently supports online-based content and will not
              be available for manually uploaded documents.
            </p>
            <p>
              You can manage what documents are watched from the{" "}
              <Link
                to={paths.experimental.liveDocumentSync.manage()}
                className="text-blue-600 underline"
              >
                File manager
              </Link>{" "}
              admin view.
            </p>
          </div>

          <div className="flex w-full justify-between items-center p-6 space-x-2 border-t rounded-b border-gray-500/50">
            <button disabled={true} className="invisible" />
            <button
              onClick={dismissAlert}
              className="border border-slate-200 px-4 py-2 rounded-lg text-white text-sm items-center flex gap-x-2 hover:bg-slate-200 hover:text-slate-800 focus:ring-gray-800"
            >
              Okay, got it
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
});

DocumentWatchAlert.displayName = "DocumentWatchAlert";

export default memo(WorkspaceDirectory);
