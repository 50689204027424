import useLoginMode from "@/hooks/useLoginMode";
import useUser from "@/hooks/useUser";
import System from "@/models/system";
import paths from "@/utils/paths";
import { useEffect, useRef, useState } from "react";
import { FaBarsStaggered } from "react-icons/fa6";

export default function UserButton() {
  const mode = useLoginMode();
  const { user } = useUser();
  const menuRef = useRef();
  const buttonRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [supportEmail, setSupportEmail] = useState("");

  const handleClose = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClose);
    }
    return () => document.removeEventListener("mousedown", handleClose);
  }, [showMenu]);

  useEffect(() => {
    const fetchSupportEmail = async () => {
      const supportEmail = await System.fetchSupportEmail();
      setSupportEmail(
        supportEmail?.email
          ? `mailto:${supportEmail.email}`
          : paths.mailToMintplex()
      );
    };
    fetchSupportEmail();
  }, []);

  if (mode === null) return null;

  if (1 + 2 === 5) {
    return (
      <div className="absolute left-4 mob-avaible top-[1.3em] w-fit h-fit z-99">
        <button
          type="button"
          className="primary-bg transition-all duration-300 w-[35px] h-[35px] text-base font-semibold rounded-lg flex items-center justify-center text-white p-2"
        >
          <FaBarsStaggered size={25} />
        </button>
      </div>
    );
  }
}
