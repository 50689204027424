import { useState } from "react";
import FileRow from "../FileRow";
import { CaretDown, FolderNotch, PencilSimple } from "@phosphor-icons/react";
import { middleTruncate } from "@/utils/directories";
import Document from "@/models/document";

export default function FolderRow({
  item,
  selected,
  onRowClick,
  toggleSelection,
  isSelected,
  autoExpanded = false,
  slug,
  updateFolderName,
}) {
  const [expanded, setExpanded] = useState(autoExpanded);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(item.name);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleRename = async () => {
    if (newName.trim() !== "" && newName !== item.name) {
      const { success } = await Document.renameFolder(item.name, newName, slug);
      if (success) {
        updateFolderName(item.name, newName);
        setIsEditing(false);
      } else {
        console.error("Failed to rename folder");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  return (
    <>
      <tr
        onClick={onRowClick}
        className={`text-white text-xs grid grid-cols-12 py-2 pl-3.5 pr-8 primary-bg cursor-pointer w-full file-row ${selected ? "selected" : ""}`}
      >
        <div className="col-span-10 flex gap-x-[4px] items-center">
          <div
            className="shrink-0 w-3 h-3 rounded border-[1px] border-white flex justify-center items-center cursor-pointer"
            role="checkbox"
            aria-checked={selected}
            tabIndex={0}
            onClick={(event) => {
              event.stopPropagation();
              toggleSelection(item);
            }}
          >
            {selected && <div className="w-3 h-3 bg-white rounded-[2px]" />}
          </div>
          <div
            onClick={handleExpandClick}
            className={`transform transition-transform duration-200 ${expanded ? "rotate-360" : " rotate-270"}`}
          >
            <CaretDown className="text-white font-bold w-4 h-4" />
          </div>
          <FolderNotch
            className="shrink-0 text-white font-bold w-4 h-4 mr-[3px]"
            weight="fill"
          />
          {isEditing ? (
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={handleRename}
              onKeyDown={handleKeyDown}
              className="bg-transparent text-white border-b border-white"
            />
          ) : (
            <p className="whitespace-nowrap overflow-show text-white">
              {middleTruncate(item.name, 60)}
            </p>
          )}
          {item.name !== "custom-documents" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              className="ml-2 opacity-0 hover:opacity-100 transition-opacity duration-200"
            >
              <PencilSimple className="text-white font-bold w-4 h-4" />
            </button>
          )}
        </div>
      </tr>
      {expanded &&
        item.items.map((fileItem) => (
          <FileRow
            key={fileItem.id}
            item={fileItem}
            selected={isSelected(fileItem.id)}
            toggleSelection={toggleSelection}
          />
        ))}
    </>
  );
}
